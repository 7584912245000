import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: "/staff",
    name: 'Staff',
    component: () => import('../views/Staff.vue')
  },
  {
    path: "/cast",
    name: 'Cast',
    component: () => import('../views/Cast.vue')
  },
  {
    path: "/world",
    name: 'World',
    component: () => import('../views/World.vue')
  },
  {
    path: "/characters",
    name: 'Characters',
    component: () => import('../views/Characters.vue')
  },
  {
    path: "/adventurers",
    name: 'Adventurers',
    component: () => import('../views/Adventurers.vue')
  },
  {
    path: "/videos",
    name: 'Videos',
    component: () => import('../views/Videos.vue')
  },
  {
    path: "/anime",
    name: 'Anime',
    component: () => import('../views/Anime.vue')
  },
  {
    path: "/movie",
    name: 'Movie',
    component: () => import('../views/Movie.vue')
  },
  {
    path: "/game",
    name: "Game",
    component: () => import('../views/Game.vue')
  },
  {
    path: "/images",
    name: 'Images',
    component: () => import('../views/Images.vue')
  },
  {
    path: "/lightnovel",
    name: 'Lightnovel',
    component: () => import('../views/Lightnovel.vue')
  },
  {
    path: "/manga",
    name: 'Manga',
    component: () => import('../views/Manga.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
