<template>
  <div class="page">
    <div class="card">
        <router-link to="/characters">Characters</router-link><br/>
        <router-link to="/anime">Goblin Slayer Anime</router-link><br/>
        <router-link to="/movie">Movie - Goblin Slayer: Goblin's Crown</router-link><br/>
        <router-link to="/cast">Cast</router-link><br/>
        <router-link to="/about">About this site</router-link><br/>
    </div>
    <div class="card">
        <h2>Synopsis</h2>
        <p>In a world of fantasy, adventurers come far and wide to join the Guild, in order to complete contracts for whatever jobs are available. An inexperienced priestess joins her first adventuring party but comes into danger after her first adventurer contract involving goblins went wrong. After the rest of her party is slaughtered, she is saved by a man known as Goblin Slayer, an adventurer whose only purpose is the eradication of goblins with extreme prejudice. <br><a href="https://goblin-slayer.fandom.com/wiki/Goblin_Slayer_Wiki">Source</a></p>
        <h2>Background</h2>
        <p>Based on the light novel series written by Kumo Kagyu, published by SoftBank Creative since February 15, 2016. The series has been adapted to a manga written by Kosuke Kurose, published by Square Enix since May 25, 2016. Both the light novels and manga have been published in English by Yen Press.<br><a href="https://myanimelist.net/anime/37349/Goblin_Slayer">Source</a></p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {}
}
</script>
